<template>
  <a-modal
    :title="title"
    :width="680"
    :visible="visible"
    :footer="null"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; max-width: 140px; min-width: 140px;">
        {{ (text / 1).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </a-modal>
</template>

<script>
import { STable } from '@/components'
import {
  credit_letter_repayment_bill_negotiation_delete,
  credit_letter_repayment_bill_negotiation_list
} from '@/api/trade_credit_letter_repayment'

// 表单字段
export default {
  components: {
    STable
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      title: '已还押汇额度占用',
      queryParam: {},
      form: this.$form.createForm(this),
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '金额',
          dataIndex: 'limit_amount',
          align: 'right',
          width: 140,
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true
        },
        {
          title: '操作人',
          dataIndex: 'create_by',
          align: 'center',
          ellipsis: true
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '80px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return credit_letter_repayment_bill_negotiation_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            // this.title = '押汇，已还金额：' + (res.data.entries.reduce((sum, item) => sum + Number(item.limit_amount), 0)).toFixed(2)
            return res.data
          })
      }
    }
  },
  created () {
    this.queryParam.credit_letter_repayment_id = this.model.id
  },
  methods: {
    handleDelete (record) {
      credit_letter_repayment_bill_negotiation_delete(record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
