<template>
  <div :bordered="false" v-if="projectInfo.project">
    <div style="border: 1px solid rgb(235, 237, 240)">
      <a-page-header
        style="border: 0px solid rgb(235, 237, 240)"
        :title="projectInfo.project[0].name"
        @back="() => this.$router.back()"
      />
      <a-descriptions style="margin-top: 10px; margin-left: 20px;">
        <a-descriptions-item label="编号">
          {{ projectInfo.project[0].number }}
        </a-descriptions-item>
        <a-descriptions-item label="主体企业">
          {{ projectInfo.project[0].host_corporation }}
        </a-descriptions-item>
        <a-descriptions-item label="交易品种">
          {{ projectInfo.project[0].category }}
        </a-descriptions-item>
        <a-descriptions-item label="信用">
          {{ (projectInfo.project[0].credit).toFixed(2) | numberFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="现金">
          {{ (projectInfo.project[0].cash).toFixed(2) | numberFormat }}
        </a-descriptions-item>
        <a-descriptions-item label="起止时间">
          {{ projectInfo.project[0].start_date }} 至 {{ projectInfo.project[0].end_date }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-space :size="space_size" class="space">
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('SellContractCreateForm')">购销合同</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('ContractCreateForm')">普通合同</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('ProjectMaterialCreateForm')">资料</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('InventoryCreateForm')">出入库</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('PaymentCreateForm')">付款</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('CollectionCreateForm')">收款</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('FapiaoCreateForm')">发票</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('CreditLetterCreateForm')">开信用证</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('CreditLetterRepaymentCreateForm')">信用证还款</a-button>
      <a-button style="width: 120px" type="primary" icon="plus" @click="handleAdd('ProjectCostCreateForm')">成本</a-button>
    </a-space>
    <a-spin :spinning="loading">
      <div>
        <div v-for="(item, index) in sortedProjectContentList" :key="(item.id + item.content_type)">
          <template v-if="item.content_type === 'sell_contract'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、购销合同</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="padding-left: 16px; display: block">
                    <span class="desc">编号：{{ item.number }}</span>
                    <span class="desc">名称：{{ item.name }}</span>
                    <span class="desc">客户：{{ item.client }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'sell_contract')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'sell_contract')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'sell_contract')">
                        <span v-if="!item.is_approval_needed || (item.is_approval_needed && item.status !== 1)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_sell_contract', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false" style="padding: 1px;">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="关键点" :style="customStyle" v-if="item.key_point.length > 0">
                        <p v-for="(point) in item.key_point" :key="point.id">{{ point.key }}：<span>{{ point.value }}</span></p>
                      </a-collapse-panel>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'contract'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、普通合同</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block; padding-left: 16px;">
                    <span class="desc">编号：{{ item.number }}</span>
                    <span class="desc">名称：{{ item.name }}</span>
                    <span class="desc">客户：{{ item.client }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'contract')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'contract')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'contract')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_contract', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'material'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、项目资料</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <div style="padding-left: 16px;">
                    <span class="desc">编号：{{ item.number }}</span>
                    <span class="desc">名称：{{ item.name }}</span>
                    <span class="desc">类型：{{ dictionaries.trade_material_type[item.type] }}</span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'project_material')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </div>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'project_material')">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'project_material')">
                        <span>删除</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'inventory'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、出入库</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block; padding-left: 16px;">
                    <span class="desc">仓库：{{ item.warehouse }}</span>
                    <span class="desc">数量：{{ item.count }}</span>
                    <span class="desc">单位：{{ dictionaries.unit[item.unit] ? dictionaries.unit[item.unit] : '--' }}</span>
                    <span class="desc">单价：{{ (item.unit_price).toFixed(2) | numberFormat }}</span>
                    <span class="desc">货币：{{ dictionaries.currency[item.currency] ? dictionaries.currency[item.currency] : '--' }}</span>
                    <span class="desc">预估人民币单价：{{ (item.unit_price_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'inventory')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc">已调整：{{ item.is_adjustment ? '是' : '否' }}</span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && (!item.is_approval_needed || (item.is_approval_needed && item.status !== 1))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'inventory')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <span @click="handleInventoryAdjust(item)" v-if="(item.is_approval_needed && item.status === 3) || (!item.is_approval_needed)">库存调整</span>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_inventory', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="2" header="库存调整" :style="customStyle" v-if="false">
                        <span class="desc">仓库：{{ item.adjustment[0].warehouse }}</span>
                        <span class="desc">数量：{{ item.adjustment[0].count }}</span>
                        <span class="desc">单位：{{ dictionaries.unit[item.adjustment[0].unit] }}</span>
                        <span class="desc">预估人民币单价：{{ item.adjustment[0].unit_price_cny }} </span>
                        <span class="desc" v-if="item.adjustment[0].adjustment_attachment.length > 0">附件：
                          <a v-for="(ada) in item.adjustment[0].adjustment_attachment" :key="ada.id" :href="ada.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ ada.name }}</a>
                        </span>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'inventory_adjustment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、出入库调整</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <div style="padding-left: 16px;">
                    <span class="desc">被调整库存：库存{{ getInventoryNumber(item.inventory_id) }}</span>
                    <span class="desc">仓库：{{ item.warehouse }}</span>
                    <span class="desc">数量：{{ item.count }}</span>
                    <span class="desc">单位：{{ dictionaries.unit[item.unit] }}</span>
                    <span class="desc">预估人民币单价：{{ item.unit_price_cny }} </span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'inventory_adjustment')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc">已调整：{{ item.is_adjustment ? '是' : '否' }}</span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </div>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'inventory_adjustment')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_inventory_adjustment', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'payment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、付款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block; padding-left: 16px;">
                    <span class="desc" >客户：{{ item.client }}</span>
                    <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                    <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc">支付方式：{{ dictionaries.trade_payment_method[item.method] }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'payment')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc">支付时间：{{ item.pay_time | dayjs }}</span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'payment')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'payment')">
                        <span v-if="((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_payment', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle">
                        <a v-for="(a) in item.attachment" :key="a.id" :href="a.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ a.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'collection'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、收款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block; padding-left: 16px;">
                    <span class="desc">客户：{{ item.client }}</span>
                    <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                    <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc">支付方式：{{ dictionaries.trade_payment_method[item.method] }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'collection')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc">支付时间：{{ item.pay_time | dayjs }}</span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'collection')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'collection')">
                        <span v-if="((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_collection', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="1" header="附件" :style="customStyle">
                        <a v-for="(a) in item.attachment" :key="a.id" :href="a.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ a.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'fapiao'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、发票</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <div style="padding-left: 16px;">
                    <span class="desc">客户：{{ item.client }}</span>
                    <span class="desc">方式：{{ dictionaries.trade_fapiao_method[item.method] }}</span>
                    <span class="desc">金额：{{ (item.total_price).toFixed(2) | numberFormat }}</span>
                    <span class="desc">税额：{{ (item.total_tax).toFixed(2) | numberFormat }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'fapiao')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </div>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'fapiao')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'fapiao')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_fapiao', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="item.detail.length > 0">
                        <div v-for="(d) in item.detail" :key="d.id" >
                          <span class="desc">名称：{{ d.name }}</span>
                          <span class="desc">数量：{{ d.count }}</span>
                          <span class="desc">单价：{{ (d.unit_price).toFixed(2) | numberFormat }}</span>
                          <span class="desc">单位：{{ dictionaries.unit[d.unit] }}</span>
                          <span class="desc">货币：{{ dictionaries.currency[d.currency] }}</span>
                          <span class="desc">税率：{{ d.rate }}%</span>
                          <span class="desc">税额：{{ (d.total_tax).toFixed(2) | numberFormat }}</span>
                          <span class="desc">含税金额：{{ (d.total_price).toFixed(2) | numberFormat }}</span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'credit_letter'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、信用证</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <span style="display: block; padding-left: 16px;">
                    <span class="desc">编号：{{ item.number }}</span>
                    <span class="desc">主体企业：{{ item.host_corporation }}</span>
                    <span class="desc">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc">货币：{{ dictionaries.currency[item.currency] }}</span>
                    <span class="desc">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc">已完成：
                      <a-popconfirm
                        title="确定已完成?"
                        :disabled="item.is_done"
                        @confirm="() => handleCreditLetterDone(item)">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_done" :disabled="item.is_done"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'credit_letter')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </span>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && !item.is_done && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'credit_letter')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'credit_letter')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_credit_letter', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false" style="padding-left: 0px;">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="item.detail.length > 0">
                        <div v-for="(d) in item.detail" :key="d.id">
                          <span class="desc">编号：{{ d.number }}</span>
                          <span class="desc">银行：{{ d.bank }}</span>
                          <span class="desc">金额：{{ (d.amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">信用证货币到人民币的汇率：{{ d.to_cny_rate }}%</span>
                          <span class="desc">预估人民币金额：{{ (d.amount_cny).toFixed(2) | numberFormat }}</span>
                          <span class="desc" v-if="false">融资利率：{{ d.financing_rate }}%</span>
                          <span class="desc">类别：{{ d.type }}</span>
                          <span class="desc">费用：{{ (d.fee).toFixed(2) | numberFormat }}</span>
                          <span class="desc">额度占用：{{ (d.limit).toFixed(2) | numberFormat }}</span>
                          <span class="desc">开证时间：{{ d.start_date }}</span>
                          <span class="desc">已完成：
                            <a-popconfirm
                              title="确定已完成?"
                              :disabled="d.is_done || item.is_archived || item.is_done"
                              @confirm="() => handleCreditLetterDetailDone(d)">
                              <a-switch checked-children="是" un-checked-children="否" :checked="d.is_done" :disabled="d.is_done || item.is_archived || item.is_done"/>
                            </a-popconfirm>
                          </span>
                          <br><span class="desc" v-if="d.remark">备注：{{ d.remark }}</span>
                          <br><span class="desc">操作:</span>
                          <span class="ope">
                            <span @click="handleEditRecord(d, 'credit_letter_detail')" v-if="!d.is_done && !item.is_archived">编辑</span>
                          </span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'credit_letter_repayment'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、信用证还款</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <div style="padding-left: 16px;">
                    <span class="desc" v-if="item.number">还证编号：{{ item.number }}</span>
                    <span class="desc" v-if="item.credit_letter">信用证编号：{{ item.credit_letter }}</span>
                    <span class="desc" v-if="item.bank">银行：{{ item.bank }}</span>
                    <span class="desc" v-if="item.bank_credit_letter_number">银行信用证编号：{{ item.bank_credit_letter_number }}</span>
                    <span class="desc" v-if="item.amount">金额：{{ (item.amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc" v-if="item.to_cny_rate">信用证货币到人民币的汇率：{{ item.to_cny_rate }}%</span>
                    <span class="desc" v-if="item.amount_cny">预估人民币金额：{{ (item.amount_cny).toFixed(2) | numberFormat }}</span>
                    <span class="desc" v-if="item.financing_rate && false">融资利率：{{ item.financing_rate }}%</span>
                    <span class="desc" v-if="item.total_amount">总金额：{{ (item.total_amount).toFixed(2) | numberFormat }}</span>
                    <span class="desc" v-if="item.repayment_date">还款日期：{{ item.repayment_date }}</span>
                    <span class="desc">是否押汇：{{ item.is_bill_negotiation ? '是' : '否' }}</span>
                    <span class="desc" v-if="item.is_bill_negotiation && item.bill_negotiation_limit !== undefined">押汇额度占用：{{ (item.bill_negotiation_limit).toFixed(2) | numberFormat }}</span>
                    <span class="desc" v-if="item.is_bill_negotiation && item.repayment_bill_negotiation_limit !== undefined" @click="showBillNegotiation(item)"><a>已还押汇额度占用：{{ (item.repayment_bill_negotiation_limit).toFixed(2) | numberFormat }}</a></span>
                    <span class="desc">已开启审批：{{ item.is_approval_needed ? '是' : '否' }}</span>
                    <span class="desc">审批状态：<span :class="statusText[item.status < 0 ? 0 : item.status][0]">{{ item.is_approval_needed ? (dictionaries.approval_result[item.status] ? dictionaries.approval_result[item.status] : '无') : un_approval_needed_text }}</span></span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'credit_letter_repayment')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </div>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived && ((item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed))">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'credit_letter_repayment')" v-if="!item.is_approval_needed">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'credit_letter_repayment')">
                        <span v-if="(item.is_approval_needed && item.status !== 1) || (!item.is_approval_needed)">删除</span>
                      </a-popconfirm>
                      <a-popconfirm
                        v-if="item.is_approval_needed && item.status === 3"
                        title="确定重启审批?"
                        @confirm="() => approvalNeeded(item, 'trade_credit_letter_repayment', true)">
                        <span>重启审批</span>
                      </a-popconfirm>
                      <span v-if="item.is_bill_negotiation && item.bill_negotiation_limit !== undefined" @click="handleAddBillNegotiation(item, 'CreateBillNegotiationForm')">押汇额度占用还款</span>
                    </span>
                  </div>
                  <div>
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle" v-if="item.attachment.length > 0">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                      <a-collapse-panel key="3" header="详情" :style="customStyle" v-if="false">
                        <div v-for="(d) in item.detail" :key="d.id">
                          <span class="desc">银行：{{ d.bank }}</span>
                          <span class="desc">金额：{{ (d.amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">信用证货币到人民币的汇率：{{ d.to_cny_rate }}%</span>
                          <span class="desc">预估人民币金额：{{ (d.amount_cny).toFixed(2) | numberFormat }}</span>
                          <span class="desc" v-if="false">融资利率：{{ d.financing_rate }}%</span>
                          <span class="desc">总金额：{{ (d.total_amount).toFixed(2) | numberFormat }}</span>
                          <span class="desc">还款日期：{{ d.repayment_date }}</span><br>
                          <span class="desc" v-if="d.remark">备注：{{ d.remark }}</span>
                        </div>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
          <template v-else-if="item.content_type === 'cost'">
            <a-row type="flex" class="row_item">
              <a-col :span="2" style="padding-top: 15px">
                <span>{{ index + 1 }}、项目成本</span>
              </a-col>
              <a-col :span="22">
                <div :title="item.name">
                  <div style="padding-left: 16px;">
                    <span class="desc">编号：{{ item.number }}</span>
                    <span class="desc">人民币金额：{{ item.amount_cny }}</span>
                    <span class="desc">类型：{{ dictionaries.trade_cost_type[item.type] }}</span>
                    <span class="desc" v-action:add>已存档：
                      <a-popconfirm
                        title="确定存档吗?"
                        :disabled="item.is_archived"
                        @confirm="() => handleArchived(item, 'project_cost')">
                        <a-switch checked-children="是" un-checked-children="否" :checked="item.is_archived" :disabled="item.is_archived"/>
                      </a-popconfirm>
                    </span>
                    <span class="desc" v-if="item.create_by">创建人：{{ item.create_by }}</span>
                    <span class="desc">创建时间：{{ item.create_time | dayjs }}</span>
                  </div>
                  <span style="padding-left: 16px;" class="desc" v-if="item.remark">备注：{{ item.remark }}</span>
                  <div style="padding-left: 16px;" v-if="!item.is_archived">
                    <span class="desc">操作:</span>
                    <span class="ope">
                      <span @click="handleEditRecord(item, 'project_cost')">编辑</span>
                      <a-popconfirm
                        title="确定删除吗?"
                        @confirm="() => handleDelete(item, 'project_cost')">
                        <span>删除</span>
                      </a-popconfirm>
                    </span>
                  </div>
                  <div v-if="item.attachment.length > 0">
                    <a-collapse :bordered="false">
                      <template #expandIcon="props">
                        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                      </template>
                      <a-collapse-panel key="2" header="附件" :style="customStyle">
                        <a v-for="(attachment) in item.attachment" :key="attachment.id" :href="attachment.url" target="_blank" class="desc"><a-icon type="file" theme="filled" /> {{ attachment.name }}</a>
                      </a-collapse-panel>
                    </a-collapse>
                  </div>
                </div>
              </a-col>
            </a-row>
          </template>
        </div>
      </div>
    </a-spin>
    <component
      :ref="currentComponent"
      @cancel="handleAddCancel"
      @ok="handleOk"
      :record="mdl"
      :is="currentComponent"
      :visible="visible"
      v-if="visible"
      :loading="confirmLoading"
      :model="mdl" />
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../../modules/CreateForm'
import SellContractCreateForm from '../../../sell_contract/modules/CreateForm'
import ContractCreateForm from '../../../contract/modules/CreateForm'
import ProjectMaterialCreateForm from '../../../project/material/modules/CreateForm'
import InventoryCreateForm from '../../../inventory/modules/CreateForm'
import InventoryAdjustmentCreateForm from '../../../inventory/adjustment/modules/CreateForm'
import PaymentCreateForm from '../../../payment/modules/CreateForm'
import CollectionCreateForm from '../../../collection/modules/CreateForm'
import FapiaoCreateForm from '../../../fapiao/modules/CreateForm'
import CreditLetterCreateForm from '../../../credit_letter/modules/CreateForm'
import CreditLetterRepaymentCreateForm from '../../../credit_letter_repayment/modules/CreateForm'
import CreateBillNegotiationForm from '../../../credit_letter_repayment/modules/CreateBillNegotiationForm'
import BillNegotiationListForm from '../../../credit_letter_repayment/modules/BillNegotiationListForm'
import ProjectCostCreateForm from '../../../cost/modules/CreateForm'
import { project_detail } from '@/api/trade_project'
import {
  sell_contract_archived,
  sell_contract_create,
  sell_contract_delete,
  sell_contract_update
} from '@/api/trade_sell_contract'
import { createProcessResult, getForms } from '@/api/trade_design'
import { contract_archived, contract_create, contract_delete, contract_update } from '@/api/trade_contract'
import {
  project_material_archived,
  project_material_create,
  project_material_delete, project_material_update
} from '@/api/trade_project_material'
import { inventory_archived, inventory_create, inventory_delete, inventory_update } from '@/api/trade_inventory'
import { payment_archived, payment_create, payment_delete, payment_update } from '@/api/trade_payment'
import { fapiao_archived, fapiao_create, fapiao_delete, fapiao_update } from '@/api/trade_fapiao'
import {
  credit_letter_archived,
  credit_letter_create,
  credit_letter_delete,
  credit_letter_update
} from '@/api/trade_credit_letter'
import {
  credit_letter_repayment_archived,
  credit_letter_repayment_bill_negotiation_create,
  credit_letter_repayment_create,
  credit_letter_repayment_delete,
  credit_letter_repayment_update
} from '@/api/trade_credit_letter_repayment'
import {
  inventory_adjustment_archived,
  inventory_adjustment_create,
  inventory_adjustment_delete, inventory_adjustment_update
} from '@/api/trade_inventory_adjustment'
import { credit_letter_detail_update } from '@/api/trade_credit_letter_detail'
import { collection_archived, collection_create, collection_delete, collection_update } from '@/api/trade_collection'
import { project_cost_archived, project_cost_create, project_cost_delete } from '@/api/trade_project_cost'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    SellContractCreateForm,
    ContractCreateForm,
    ProjectMaterialCreateForm,
    InventoryCreateForm,
    InventoryAdjustmentCreateForm,
    PaymentCreateForm,
    CollectionCreateForm,
    FapiaoCreateForm,
    CreditLetterCreateForm,
    CreditLetterRepaymentCreateForm,
    ProjectCostCreateForm,
    CreateBillNegotiationForm,
    BillNegotiationListForm
  },
  data () {
    return {
      space_size: 8,
      loading: true,
      confirmLoading: false,
      dictionaries: Dictionaries,
      un_approval_needed_text: '未开启审批',
      currentComponent: '',
      // 创建窗口控制
      visible: false,
      visibleBillNegotiation: false,
      mdl: {},
      inventoryMdl: {},
      statusText: {
        0: ['none', '无'],
        1: ['waiting', '待审批'],
        2: ['pass', '通过'],
        3: ['un_pass', '驳回']
      },
      projectInfo: {},
      projectContentList: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '主体企业',
          dataIndex: 'host_corporation'
        },
        {
          title: '交易品种',
          dataIndex: 'category'
        },
        {
          title: '编号',
          dataIndex: 'number'
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '信用',
          dataIndex: 'credit'
        },
        {
          title: '现金',
          dataIndex: 'cash'
        },
        {
          title: '起止时间',
          dataIndex: 'start_date',
          customRender: (text, record) => text === '1970-01-01' ? '' : text + '到' + record.end_date
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 1px;border: 0;overflow: hidden',
      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.mdl.id = this.$route.query.record
    this.projectDetailInfo()
  },
  computed: {
    sortedProjectContentList () {
      return [...this.projectContentList].sort((a, b) => new Date(a.create_time) - new Date(b.create_time))
    }
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd (component) {
      const date = new Date()
      const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      this.mdl = { number: this.projectInfo.project[0].number + hours + minutes }
      this.currentComponent = component
      this.visible = true
    },
    handleAddSellContract () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs[this.currentComponent].form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id })
    },
    handleClient (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/client', query: { record: record.host_corporation } })
    },
    handleRemark (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/trade/project/' + record.id + '/remark', query: { record: record.host_corporation } })
    },
    handleDetail (record) {
      console.log(record)
    },
    handleDelete (record, type) {
      this.loading = true
      this.deleteByType(type)(record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        } else {
          this.loading = false
        }
      })
    },
    handleArchived (record, type) {
      this.loading = true
      this.archivedByType(type)({ ids: [record.id], is_archived: true }).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        } else {
          this.loading = false
        }
      })
    },
    handleOk (data) {
      const form = this.$refs[this.currentComponent].form
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, data)
            // 新增
          values = { ...values, ...data }
          values.project_id = this.$route.params.project_id
          if (this.currentComponent === 'CreateBillNegotiationForm') {
            this.createBillNegotiation(values)
            return
          } else if (this.currentComponent === 'BillNegotiationListForm') {
            this.handleAddCancel()
            return
          }
          if (this.currentComponent === 'InventoryAdjustmentCreateForm') {
            values.inventory_id = this.mdl.id
          }
          if (values.is_approval_needed) {
            this.checkApprovalTemplate(this.filterComponentApprovalType(), false, this.$route.query.host_corporation_id, values, form)
          } else {
            this.createProjectContent(values, form)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    createProjectContent (values, form) {
      if (!this.confirmLoading) {
        this.confirmLoading = true
      }
      this.createByType()(values).then(res => {
        this.visible = false
        // 重置表单数据
        form.resetFields()
        // 刷新表格
        if (res.code === 1000) {
          this.projectDetailInfo()
          if (values.is_approval_needed) {
            this.geneApprovalInfo(res.data.id, this.filterComponentApprovalType())
          }
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    createByType () {
      if (this.currentComponent === 'SellContractCreateForm') {
        return sell_contract_create
      } else if (this.currentComponent === 'ContractCreateForm') {
        return contract_create
      } else if (this.currentComponent === 'ProjectMaterialCreateForm') {
        return project_material_create
      } else if (this.currentComponent === 'InventoryCreateForm') {
        return inventory_create
      } else if (this.currentComponent === 'InventoryAdjustmentCreateForm') {
        return inventory_adjustment_create
      } else if (this.currentComponent === 'PaymentCreateForm') {
        return payment_create
      } else if (this.currentComponent === 'CollectionCreateForm') {
        return collection_create
      } else if (this.currentComponent === 'FapiaoCreateForm') {
        return fapiao_create
      } else if (this.currentComponent === 'CreditLetterCreateForm') {
        return credit_letter_create
      } else if (this.currentComponent === 'CreditLetterRepaymentCreateForm') {
        return credit_letter_repayment_create
      } else if (this.currentComponent === 'ProjectCostCreateForm') {
        return project_cost_create
      }
    },
    deleteByType (type) {
      if (type === 'sell_contract') {
        return sell_contract_delete
      } else if (type === 'contract') {
        return contract_delete
      } else if (type === 'project_material') {
        return project_material_delete
      } else if (type === 'inventory') {
        return inventory_delete
      } else if (type === 'inventory_adjustment') {
        return inventory_adjustment_delete
      } else if (type === 'payment') {
        return payment_delete
      } else if (type === 'collection') {
        return collection_delete
      } else if (type === 'fapiao') {
        return fapiao_delete
      } else if (type === 'credit_letter') {
        return credit_letter_delete
      } else if (type === 'credit_letter_repayment') {
        return credit_letter_repayment_delete
      } else if (type === 'project_cost') {
        return project_cost_delete
      }
    },
    archivedByType (type) {
      if (type === 'sell_contract') {
        return sell_contract_archived
      } else if (type === 'contract') {
        return contract_archived
      } else if (type === 'project_material') {
        return project_material_archived
      } else if (type === 'inventory') {
        return inventory_archived
      } else if (type === 'inventory_adjustment') {
        return inventory_adjustment_archived
      } else if (type === 'payment') {
        return payment_archived
      } else if (type === 'collection') {
        return collection_archived
      } else if (type === 'fapiao') {
        return fapiao_archived
      } else if (type === 'credit_letter') {
        return credit_letter_archived
      } else if (type === 'credit_letter_repayment') {
        return credit_letter_repayment_archived
      } else if (type === 'project_cost') {
        return project_cost_archived
      }
    },
    approvalNeededByType (type) {
      if (type === 'trade_sell_contract') {
        return sell_contract_update
      } else if (type === 'trade_contract') {
        return contract_update
      } else if (type === 'trade_project_material') {
        return project_material_update
      } else if (type === 'trade_inventory') {
        return inventory_update
      } else if (type === 'trade_inventory_adjustment') {
        return inventory_adjustment_update
      } else if (type === 'trade_payment') {
        return payment_update
      } else if (type === 'trade_collection') {
        return collection_update
      } else if (type === 'trade_fapiao') {
        return fapiao_update
      } else if (type === 'trade_credit_letter') {
        return credit_letter_update
      } else if (type === 'trade_credit_letter_repayment') {
        return credit_letter_repayment_update
      }
    },
    filterComponentApprovalType () {
      if (this.currentComponent === 'SellContractCreateForm') {
        return 'trade_sell_contract'
      } else if (this.currentComponent === 'ContractCreateForm') {
        return 'trade_contract'
      } else if (this.currentComponent === 'InventoryCreateForm') {
        return 'trade_inventory'
      } else if (this.currentComponent === 'InventoryAdjustmentCreateForm') {
        return 'trade_inventory_adjustment'
      } else if (this.currentComponent === 'PaymentCreateForm') {
        return 'trade_payment'
      } else if (this.currentComponent === 'CollectionCreateForm') {
        return 'trade_collection'
      } else if (this.currentComponent === 'FapiaoCreateForm') {
        return 'trade_fapiao'
      } else if (this.currentComponent === 'CreditLetterCreateForm') {
        return 'trade_credit_letter'
      } else if (this.currentComponent === 'CreditLetterRepaymentCreateForm') {
        return 'trade_credit_letter_repayment'
      } else if (this.currentComponent === 'ProjectCostCreateForm') {
        return 'trade_cost'
      }
    },
    handleInventoryAdjust (record) {
      const date = new Date()
      const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      this.mdl = { number: this.projectInfo.project[0].number + hours + minutes, ...record }
      this.currentComponent = 'InventoryAdjustmentCreateForm'
      this.visible = true
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    projectDetailInfo () {
      if (!this.loading) {
        this.loading = true
      }
      project_detail({ project_id: this.$route.params.project_id }).then(res => {
        console.log(res)
        this.projectInfo = res.data
        this.projectContentList = []
        this.projectContentList.push(...this.projectInfo.sell_contract_list.map(item => {
          item.content_type = 'sell_contract'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.contract_list.map(item => {
          item.content_type = 'contract'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.material_list.map(item => {
          item.content_type = 'material'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.inventory_list.map(item => {
          item.content_type = 'inventory'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.inventory_adjustment_list.map(item => {
          item.content_type = 'inventory_adjustment'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.payment_list.map(item => {
          item.content_type = 'payment'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.collection_list.map(item => {
          item.content_type = 'collection'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.fapiao_list.map(item => {
          item.content_type = 'fapiao'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.credit_letter_list.map(item => {
          item.content_type = 'credit_letter'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.credit_letter_repayment_list.map(item => {
          item.content_type = 'credit_letter_repayment'
          return item
        }))
        this.projectContentList.push(...this.projectInfo.cost_list.map(item => {
          item.content_type = 'cost'
          return item
        }))
      }).finally(() => {
        this.loading = false
      })
    },
    approvalNeeded (record, type, approval_again = false) {
      this.loading = true
      const is_approval_needed = approval_again ? true : !record.is_approval_needed
      this.approvalNeededByType(type)({ is_approval_needed: is_approval_needed }, record.id).then((res) => {
        if (res.code === 1000) {
          this.projectDetailInfo()
          if (is_approval_needed) {
            this.geneApprovalInfo(res.data.id, type, approval_again)
          }
        } else {
          this.loading = false
        }
      })
    },
    geneApprovalInfo (id, type, approval_again) {
      this.createContractApproval(id, type, approval_again)
    },
    createContractApproval (id, type, approval_again) {
      getForms({ is_stop: 0, template_type: type, data_id: id }).then(res => {
        const shenpi_list = res.data.entries
        if (shenpi_list && shenpi_list.length > 0) {
          for (let i = 0, len = shenpi_list.length; i < len; i++) {
            const item = shenpi_list[i]
            if (item.template_type === type) {
              this.createProcessNotice(item.id, id, type, approval_again)
              break
            }
          }
        } else {
          this.$message.warning('先创建审批模板')
          // this.createProcessNotice(-1, ids, type)
        }
      })
    },
    checkApprovalTemplate (type, approval_again, host_corporation_id, values, form) {
      this.confirmLoading = true
      getForms({ is_stop: 0, template_type: type, host_corporation_id: host_corporation_id }).then(res => {
        const shenpi_list = res.data.entries
        if (shenpi_list && shenpi_list.length > 0) {
          // 有审批模板
          this.createProjectContent(values, form)
        } else {
          if (this.confirmLoading) {
            this.confirmLoading = false
          }
          this.$message.warning('先创建审批模板')
          // this.createProcessNotice(-1, ids, type)
        }
      }).catch(err => {
        console.log(err)
        if (this.confirmLoading) {
          this.confirmLoading = false
        }
      })
    },
    createProcessNotice (template_id, ids, type, approval_again = false) {
      const data = {
        'data_ids': [ids],
        'template_id': template_id,
        'template_type': type,
        'approval_again': approval_again
      }
      createProcessResult(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    handleEditRecord (record, type) {
      if (type === 'inventory_adjustment') {
        console.log(record, type)
        this.$router.push({ path: '/trade/inventory/' + record.inventory_id + '/adjustment/' + record.id })
        return
      }
      this.$router.push({ path: '/trade/' + type + '/' + record.id, query: { record: record.host_corporation } })
    },
    handleCreditLetterDone (record) {
      if (record.is_approval_needed && record.status === 1) {
        this.$message.warning('审批还未结束')
        return
      }
      credit_letter_update({ is_done: true }, record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    handleCreditLetterDetailDone (record) {
      credit_letter_detail_update({ is_done: true }, record.id).then(res => {
        if (res.code === 1000) {
          this.projectDetailInfo()
        }
      })
    },
    getInventoryNumber (inventory_id) {
      let index = 0
      this.sortedProjectContentList.forEach((item, i) => {
        if (item.id === inventory_id && item.content_type === 'inventory') {
          index = i + 1
        }
      })
      return index
    },
    handleAddBillNegotiation (record, component) {
      console.log(record)
      this.mdl = { credit_letter_repayment_id: record.id }
      this.currentComponent = component
      this.visible = true
    },
    createBillNegotiation (values) {
      const data = { ...values, credit_letter_repayment_id: this.mdl.credit_letter_repayment_id }
      credit_letter_repayment_bill_negotiation_create(data).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.visible = false
          this.projectDetailInfo()
        }
      }).finally(() => {
        if (this.confirmLoading) {
          this.confirmLoading = false
        }
      })
    },
    showBillNegotiation (item) {
      if (item.repayment_bill_negotiation_limit === 0) {
        this.$message.info('暂无数据')
        return
      }
      this.mdl = item
      this.currentComponent = 'BillNegotiationListForm'
      this.visible = true
    }
  }
}
</script>
<style scoped>
  .desc {
    padding-right: 20px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  .row_item {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 20px;
    background-color: #f7f7f7;
    margin-top: 5px;
    border-radius: 10px;
  }
  .ope>span:hover {
    color: #2a99ff;
    cursor: pointer;
  }
  .ope>span:first-child {
    padding-left: 0px;
    height: 40px;
    line-height: 40px;
  }
  .ope>span:not(:first-child) {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
  }
  .content {
    /*color: #00A0E9;*/
    /*font-weight: bolder*/
  }
  .none {
    color: #333333;
  }
  .waiting {
    color: yellowgreen;
  }
  .pass {
    color: green;
  }
  .un_pass {
    color: red;
  }
  .space {
    margin-top: 5px;
    overflow-x: auto;
  }
</style>
